<template>
    <div class="admin_page_body" v-loading="isRequestLoading">
        <el-form ref="form" :inline="true" :model="form" :rules="rules" size="small" label-position="top">
            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">商品基本信息</h1>
                <div class="data_detail mt24">
                    <el-form-item label="商品分类" class="full">
                        <div class="item">
                            <span class="text ml0">{{form.category1Value}} > {{form.category2Value}} > {{form.category3Value}}</span>
                            <el-button type="default" size="small" class="ml16" @click="isPopupClass = true">选择商品分类</el-button>
                        </div>
                    </el-form-item>

                    <el-form-item label="商品名称" prop="name">
                        <div class="flexColumn">
                            <el-input v-model="form.name" placeholder="请输入商品名称"></el-input>
                            <span class="el-upload__tip">商品标题名称至少3个字符，最长50个汉字</span>
                        </div>
                    </el-form-item>

                    <el-form-item label="商品缩略图" prop="imageUrl" class="full">
                        <el-upload
                                class="avatar-uploader"
                                :action="uploadURL"
                                :before-upload="beforeUploadImage"
                                :on-success="imageUrlUpload"
                                :show-file-list="false">
                            <img v-if="form.imageUrl" :src="imageURL + form.imageUrl" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                </div>
            </div>

            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">商品属性信息</h1>
                <span class="el-upload__tip mt18">正确选择商品属性直接影响筛选准确与否，请根据实际情况选择填写</span>

                <div class="data_detail mt24">
                    <el-form-item v-for="(item, index) in classAttrData" :key="index" :label="item.name">
                        <el-select v-model="form.tag[index].value" :placeholder="'请选择' + item.name">
                            <el-option v-for="(option, j) in item.value" :key="j" :label="option" :value="option"></el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>

            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">商品规格及图片</h1>

                <div class="data_detail mt24">
                    <el-form-item label="商品规格" class="full" prop="skuType">
                        <el-table :data="changeFormatData" style="width: 100%" v-loading="isRequestLoading">
                            <el-table-column prop="name" label="规格" width="160px"></el-table-column>
                            <el-table-column label="规格值">
                                <template slot-scope="scope">
                                    <el-checkbox-group v-model="scope.row.skuValue">
                                        <el-checkbox v-for="(item, index) in scope.row.value" :key="index" :label="item" @change="toChangeSkuCheck"></el-checkbox>
                                    </el-checkbox-group>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="width: 100%; text-align: center">
                            <el-button type="default" size="small" class="mt18" icon="el-icon-plus" @click="isPopupFormat = true">新增规格</el-button>
                        </div>
                    </el-form-item>

                    <el-form-item label="商品价格及库存" class="full" prop="skus">
                        <el-table :data="form.skus" style="width: 100%">
                            <el-table-column v-for="(item, index) in form.skuType" :key="index" :label="item.skukey">
                                <template slot-scope="scope">
                                    {{scope.row.sku[index].skuValue}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="address" label="价格（元）">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.price" placeholder="请输入价格" @blur="skuPriceChange(scope.$index)"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="address" label="库存">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.stock" placeholder="请输入库存" maxlength="8" @blur="skuStockChange(scope.$index)"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="address" label="商家编码">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.skuCode" placeholder="请输入商家编码"></el-input>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>

                    <el-form-item label="产品图片" class="full mt49" prop="image">
                        <el-table :data="form.image" style="width: 100%" v-loading="isRequestLoading">
                            <el-table-column label="规格" width="160px">
                                <template slot-scope="scope">
                                    {{scope.row.sku.skuValue}}
                                </template>
                            </el-table-column>
                            <el-table-column label="图片">
                                <template slot-scope="scope">
                                    <el-upload
                                            :key="scope.$index"
                                            class="avatar-uploader"
                                            :action="uploadURL"
                                            multiple
                                            :limit="5"
                                            list-type="picture-card"
                                            :before-upload="beforeUploadImage"
                                            :on-success=" (res) => imageUploadSuccess(scope.$index, res)"
                                            :on-remove=" (file, fileList) => imageUploadRemove(scope.$index, file, fileList)"
                                            :on-exceed="imageUploadExceed"
                                            :file-list="getFileList(scope.row.imageValue)">
                                        <i class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-form-item>
                </div>
            </div>

            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">商品详情描述</h1>

                <div class="data_detail mt24">
                    <el-form-item label="商品描述" class="full" prop="detail">
                        <tinymceEditor :content="form.detail" @changeValue="formDetailChange" />
                    </el-form-item>
                </div>
            </div>

            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">其他信息</h1>

                <div class="data_detail mt24">
                    <el-form-item label="售卖群体" class="full" prop="channel">
                        <div class="flexRow">
                            <el-checkbox-group v-model="form.channel">
                                <el-checkbox :label="1">商家</el-checkbox>
                                <el-checkbox :label="2">个人</el-checkbox>
                            </el-checkbox-group>
                            <span class="el-upload__tip">（此选项选择后，需买家店面取货，且个人买家必须经过平台审核）</span>
                        </div>
                    </el-form-item>
                </div>

                <div class="data_detail mt24">
                    <el-form-item label="商品发布" class="full">
                        <el-radio-group v-model="form.status">
                            <el-radio :label="1">立即发布</el-radio>
                            <el-radio :label="2">定时发布</el-radio>
                            <el-radio :label="0">放入仓库</el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="发布时间" prop="releaseAt" v-if="form.status === 2">
                        <el-date-picker v-model="form.releaseAt" type="datetime" placeholder="请选择发布时间" value-format="yyyy-MM-dd HH:mm:ss" :picker-options="pickerOptions" :default-value="defaultDate" popper-class="goodsReplaceDate"></el-date-picker>
                    </el-form-item>
                </div>
            </div>

            <div class="page_footer mt24">
                <el-button type="primary" size="small" :loading="isSubmitLoading" @click="submitForm('form')">保存商品修改</el-button>
                <el-button type="default" size="small" @click="$router.go(-1)">取消修改</el-button>
            </div>
        </el-form>

        <el-dialog class="popup_dialog" title="添加商品规格" :visible.sync="isPopupFormat" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="800px">
            <el-table ref="goodsFormatData" :data="goodsFormatData" style="width: 100%" class="addGoodsChangeFormatTable">
<!--                @select="toAddFormatChange"-->
<!--                <el-table-column type="selection" width="50px"></el-table-column>-->
                <el-table-column width="50px" align="center">
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.isChange" :checked="scope.row.isChange" @change="(e) => toAddFormatChange(e, scope.$index)"></el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="规格" width="100px"></el-table-column>
                <el-table-column prop="value" label="规格值"></el-table-column>
                <el-table-column label="顺序" width="120px">
                    <template slot-scope="scope">
                        <el-input v-model.number="scope.row.num" placeholder="输入排序值" size="small"></el-input>
                    </template>
                </el-table-column>
            </el-table>

            <div slot="footer" class="dialog-footer">
                <el-button @click="isPopupFormat = false" size="small">取 消</el-button>
                <el-button type="primary" @click="toAddFormat()" size="small">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog class="popup_dialog" title="选择商品分类" :visible.sync="isPopupClass" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true" width="1200px">
            <el-form ref="form" :inline="true" :model="form" size="small" label-position="top">
                <div class="admin_page_item mt18">
                    <div class="admin_change_type">
                        <div class="group">
                            <div :class="category1Id === item.id ? 'on' : ''" class="item" v-for="(item, index) in classLevel1" :key="index" @click="changeLevel(1, item.id, item.name)">{{item.name}}</div>
                        </div>

                        <div class="group">
                            <div class="search">
                                <el-input placeholder="请输入分类名称" v-model="keyword2" @input="category2Change">
                                    <i class="el-icon-search" slot="append"></i>
                                </el-input>
                            </div>
                            <div class="list">
                                <div :class="category2Id === item.id ? 'on' : ''" class="item" v-for="(item, index) in classLevel2Show" :key="index" @click="changeLevel(2, item.id, item.name)">{{item.name}}</div>
                            </div>
                        </div>

                        <div class="group">
                            <div class="search">
                                <el-input placeholder="请输入分类名称" v-model="keyword3" @input="category3Change">
                                    <i class="el-icon-search" slot="append"></i>
                                </el-input>
                            </div>
                            <div class="list">
                                <div :class="category3Id === item.id ? 'on' : ''" class="item" v-for="(item, index) in classLevel3Show" :key="index" @click="changeLevel(3, item.id, item.name)">{{item.name}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="legend_main pl0">
                        <p class="text">您当前选择的商品类别：
                            <span class="title" v-if="category1Value">{{category1Value}}</span>
                            <span class="title" v-if="category2Value">{{' / ' + category2Value}}</span>
                            <span class="title" v-if="category3Value">{{' / ' + category3Value}}</span>
                        </p>
                    </div>
                </div>
            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="isPopupClass = false" size="small">取 消</el-button>
                <el-button type="primary" @click="toChangeClass" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
  import {
    getGoodsInfo,
    getGoodsFormat,
    getClassAttr,
    putGoods,
    getGoodsSkuCode,
    getGoods,
    getMerchantClass
  } from "../../../api/admin/goods";
  import {imageURL, uploadURL} from "../../../utils/config";
  import {beforeUploadImage} from '../../../utils/method'
  const tinymceEditor = () => import('../../../components/layout/admin/tinymceEditor')
  export default {
    name: "adminGoodsAddInfo",
    data () {
      return {
        steps: 1,
        form: {
          category1Id: null,
          category1Value: '',
          category2Id: null,
          category2Value: '',
          category3Id: null,
          category3Value: '',
          name: '',
          imageUrl: '',
          tag: [],
          skuType: [],
          skus: [],
          image: [],
          detail: '',
          channel: [],
          status: 1,
          releaseAt: ''
        },
        rules: {
          name: [
            {required: true, message: '请输入商品名称', trigger: 'blur'},
            { min: 3, max: 100, message: '商品标题名称至少3个字符，最长50个汉字', trigger: 'blur' }
          ],
          skuType: [
            {required: true, message: '请选择商品规格', trigger: 'change'}
          ],
          imageUrl: [
            {required: true, message: '请上传商品缩略图', trigger: 'change'}
          ],
          skus: [
            {required: true, message: '请选择商品规格', trigger: 'change'}
          ],
          image: [
            {required: true, message: '请选择商品规格', trigger: 'change'}
          ],
          detail: [
            {required: true, message: '请输入商品描述', trigger: 'blur'}
          ],
          channel: [
            {required: true, message: '请选择售卖群体', trigger: 'change'}
          ],
          releaseAt: [
            {required: true, message: '请选择发布时间', trigger: 'change'}
          ],
        },
        goodsFormatData: [],
        changeFormatData: [],
        classAttrData: [],
        isPopupFormat: false,
        imageURL: imageURL,
        uploadURL: uploadURL,
        isRequestLoading: false,
        radio: '',
        isSubmitLoading: false,
        beforeUploadImage: beforeUploadImage,
        skus: [],
        image: [],
        isPopupClass: false,
        classLevel1: [],
        classLevel2: [],
        classLevel2Show: [],
        classLevel3: [],
        classLevel3Show: [],
        category1Id: null,
        category1Value: '',
        category2Id: null,
        category2Value: '',
        category3Id: null,
        category3Value: '',
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now();
          },
        },
        keyword2: '',
        keyword3: '',
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getGoodsInfo()
      this.getSkuList()
      this.getMerchantClass(1, 0)
    },
    computed: {
      defaultDate: function () {
        let date = new Date(new Date().getTime() + (60 * 60 * 24 * 1000));
        let y = date.getFullYear();
        let m = date.getMonth();
        let d = date.getDate();
        return y + '-' + ((m + 1) < 10 ? '0' + (m + 1) : (m + 1)) + '-' + (d < 10 ? '0' + d : d) + ' 00:00:00'
      },
    },
    methods: {
      getGoodsInfo () {
        this.isRequestLoading = true
        getGoodsInfo(this.$route.query.id).then(res => {
          if (res.code === 0) {
            this.form.category1Id = res.data.category1Id
            this.form.category1Value =res. data.category1Value
            this.form.category2Id = res.data.category2Id
            this.form.category2Value = res.data.category2Value
            this.form.category3Id = res.data.category3Id
            this.form.category3Value = res.data.category3Value
            this.form.name = res.data.name
            this.form.imageUrl = res.data.imageUrl
            this.form.tag = res.data.tag
            this.form.skuType = res.data.skuType
            this.form.image = res.data.imageSku
            this.image = res.data.imageSku
            this.form.detail = res.data.detail
            this.form.status = res.data.status
            this.form.releaseAt = res.data.releaseAt

            if (res.data.channel === 0) {
              this.form.channel = [1, 2]
            } else if (res.data.channel === 1) {
              this.form.channel = [1]
            } else if (res.data.channel === 2) {
              this.form.channel = [2]
            }

            this.getClassAttr()
            this.getFormatList()
          } else {
            this.isRequestLoading = false
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      getSkuList () {
        getGoods({
          queryType: 1,
          goodsId: this.$route.query.id
        }).then(res => {
          this.isRequestLoading = false
          if (res.code === 0) {
            let list = []
            res.data.records.forEach(item => {
              list.push({
                sku: item.sku,
                price: item.price,
                stock: item.stock,
                skuCode: item.skuCode,
                id: item.id
              })
            })
            this.form.skus = list
            this.skus = list
          }
        }).catch(() => {
          this.isRequestLoading = false
        })
      },
      getFormatList () {
        getGoodsFormat().then(res => {
          if (res.code === 0) {
            let data = res.data
            data.forEach(item => {
              let isChangeFormatHas = false
              this.form.skuType.forEach((sku, index) => {
                let union = item.value.split(',').filter(function (val) { return (sku.skuvalue.indexOf(val) > -1) })
                if (sku.skukey === item.name && union.length === sku.skuvalue.length) {
                  item.num = index + 1
                  item.isChange = true
                  isChangeFormatHas = true
                }
              })
              if (!isChangeFormatHas) {
                item.num = null
                item.isChange = false
              }
            })
            this.goodsFormatData = data
            this.toAddFormat()
          }
        })
      },
      getClassAttr () {
        getClassAttr(this.form.category3Id).then(res => {
          if (res.code === 0) {
            let classAttrData = res.data.attribute

            if (classAttrData.length > this.form.tag.length) {
              let num = classAttrData.length - this.form.tag.length
              let n = this.form.tag.length
              for (let i = 0; i < num; i++) {
                this.form.tag.push({
                  name: classAttrData[n + i].name,
                  value: ''
                })
              }
            }

            if (classAttrData.length < this.form.tag.length) {
              let num = this.form.tag.length - classAttrData.length
              for (let i = 0; i < num; i++) {
                this.form.tag.splice(this.form.tag.length - 1, 1)
              }
            }

            this.classAttrData = classAttrData
          }
        })
      },
      toAddFormatChange (val, index) {
        if (val) {
          let changeLength = this.goodsFormatData.filter(item => item.isChange).length
          if (changeLength > 3) {
            this.$message({
              message: '最多可选择三个规格',
              type: 'warning',
              duration: 2 * 1000
            })
            this.$nextTick(() => {
              this.goodsFormatData[index].isChange = false
            })
          }
        }
      },
      toAddFormat () {
        let data = []
        let isError = false
        this.goodsFormatData.forEach(item => {
          if (item.isChange) {
            data.push({
              id: item.id,
              name: item.name,
              num: item.num,
              isChange: item.isChange,
              value: item.value.split(','),
              skuValue: []
            })

            if (!item.num) {
              isError = true
            }
          }
        })
        if (data.length === 0) {
          this.$message({
            message: '请选择规格',
            type: 'warning',
            showClose: true,
          });
          return false;
        }
        if (isError) {
          this.$message({
            message: '请输入排序值',
            type: 'warning',
            showClose: true,
          });
          return false;
        }
        this.isPopupFormat = false
        this.changeFormatData = data.sort(this.compare('num'))

        this.changeFormatData.forEach(item => {
          let skuTypeList = this.form.skuType.filter(e => e.skukey === item.name)
          item.skuValue = skuTypeList.length > 0 ? skuTypeList[0].skuvalue : []
        })
        this.toChangeSkuCheck()
      },
      compare (property) {
        return function (a, b) {
          let value1 = a[property];
          let value2 = b[property];
          return value1 - value2;
        }
      },
      toChangeSkuCheck () {
        let data = []
        let skuData = []
        let images = []

        for (let i in this.changeFormatData) {
          if (this.changeFormatData[i].skuValue.length > 0) {
            data.push({
              skukey: this.changeFormatData[i].name,
              skuvalue: this.changeFormatData[i].skuValue
            })
          }
        }

        if (data.length === 1) {
          for (let i in data[0].skuvalue) {
            skuData.push({
              sku: [
                {
                  skuKey: data[0].skukey,
                  skuValue: data[0].skuvalue[i]
                }
              ],
              price: '',
              stock: '',
              skuCode: ''
            })

            images.push({
              sku: {
                skuKey: data[0].skukey,
                skuValue: data[0].skuvalue[i]
              },
              imageValue: []
            })
          }
        }

        if (data.length === 2) {
          for (let i in data[0].skuvalue) {
            images.push({
              sku: {
                skuKey: data[0].skukey,
                skuValue: data[0].skuvalue[i]
              },
              imageValue: []
            })

            for (let j in data[1].skuvalue) {
              skuData.push({
                sku: [
                  {
                    skuKey: data[0].skukey,
                    skuValue: data[0].skuvalue[i]
                  }, {
                    skuKey: data[1].skukey,
                    skuValue: data[1].skuvalue[j]
                  }
                ],
                price: '',
                stock: '',
                skuCode: ''
              })
            }
          }
        }

        if (data.length === 3) {
          for (let i in data[0].skuvalue) {
            images.push({
              sku: {
                skuKey: data[0].skukey,
                skuValue: data[0].skuvalue[i]
              },
              imageValue: []
            })

            for (let j in data[1].skuvalue) {
              for (let k in data[2].skuvalue) {
                skuData.push({
                  sku: [
                    {
                      skuKey: data[0].skukey,
                      skuValue: data[0].skuvalue[i]
                    }, {
                      skuKey: data[1].skukey,
                      skuValue: data[1].skuvalue[j]
                    }, {
                      skuKey: data[2].skukey,
                      skuValue: data[2].skuvalue[k]
                    }
                  ],
                  price: '',
                  stock: '',
                  skuCode: ''
                })
              }
            }
          }
        }

        skuData.forEach(item => {
          for (let i in this.skus) {
            if (this.equalsObj(item.sku, this.skus[i].sku)) {
              item.price = this.skus[i].price
              item.stock = this.skus[i].stock
              item.skuCode = this.skus[i].skuCode
              item.id = this.skus[i].id
              break
            }
          }
        })

        images.forEach(item => {
          for (let i in this.image) {
            if (this.equalsObj(item.sku, this.image[i].sku)) {
              item.imageValue = this.image[i].imageValue
              break
            }
          }
        })

        this.form.skuType = data
        this.form.skus = skuData
        this.form.image = images
      },
      imageUploadSuccess (index, res) {
        if (res.code === 0) {
          this.form.image[index].imageValue.push(res.data)
        }
      },
      imageUrlUpload (res) {
        if (res.code === 0) {
          this.form.imageUrl = res.data
        }
      },
      imageUploadRemove (index, file, fileList) {
        let data = []
        fileList.forEach(item => {
          data.push(item.response.data)
        })
        this.form.image[index].imageValue = data
      },
      imageUploadExceed () {
        this.$message({
          message: '请上传不超过5张的图片',
          type: 'warning',
          duration: 2 * 1000
        })
      },
      submitForm (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let isErrorTag = false
            let isErrorSku = false
            let isErrorImage = false

            for (let i in this.form.tag) {
              if (this.form.tag[i].value === '') {
                isErrorTag = true;
                break;
              }
            }

            if (isErrorTag) {
              this.$message({
                message: '请选择商品属性',
                type: 'warning',
                showClose: true,
              });
              return false;
            }

            for (let i in this.form.skus) {
              if (this.form.skus[i].price === '' || this.form.skus[i].stock === '' || this.form.skus[i].skuCode === '') {
                isErrorSku = true;
                break;
              }
            }

            if (isErrorSku) {
              this.$message({
                message: '请补全商品价格及库存',
                type: 'warning',
                showClose: true,
              });
              return false;
            }

            for (let i in this.form.image) {
              if (this.form.image[i].imageValue.length === 0) {
                isErrorImage = true;
                break;
              }
            }

            if (isErrorImage) {
              this.$message({
                message: '请上传商品图片',
                type: 'warning',
                showClose: true,
              });
              return false;
            }

            if (this.form.status === 2) {
              if (new Date(this.form.releaseAt).getTime() < new Date().getTime() || new Date(this.form.releaseAt).getTime() === new Date().getTime()) {
                this.$message({
                  message: '发布时间不可小于当前时间',
                  type: 'warning',
                  showClose: true,
                });
                return false;
              }
            }

            this.isSubmitLoading = true

            let channel = null
            if (this.form.channel.length === 1) {
              channel = this.form.channel[0]
            } else if (this.form.channel.length === 2) {
              channel = 0
            }

            putGoods(this.$route.query.id, {
              data: {
                category1Id: this.form.category1Id,
                category1Value: this.form.category1Value,
                category2Id: this.form.category2Id,
                category2Value: this.form.category2Value,
                category3Id: this.form.category3Id,
                category3Value: this.form.category3Value,
                name: this.form.name,
                imageUrl: this.form.imageUrl,
                tag: this.form.tag,
                skuType: this.form.skuType,
                skus: this.form.skus,
                image: this.form.image,
                detail: this.form.detail,
                channel: channel,
                status: this.form.status,
                releaseAt: this.form.releaseAt
              }
            }).then(res => {
              this.isSubmitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: '商品修改成功',
                  type: 'success',
                  showClose: true,
                });
                this.$router.go(-1)
              }
            }).catch(() => {
              this.isSubmitLoading = false
            })
          } else {
            return false;
          }
        });
      },
      skuPriceChange (index) {
        if (this.form.skus[index].price === '') {
          return false
        }
        let reg = /^\d+(\.\d{1,2})?$/;
        if(!reg.test(this.form.skus[index].price)){
          this.form.skus[index].price = '';
          this.$message({
            message: '请输入正确的金额',
            type: 'warning',
            showClose: true,
          });
          return false;
        }

        if (Number(this.form.skus[index].price) < 0 || Number(this.form.skus[index].price) === 0) {
          this.form.skus[index].price = '';
          this.$message({
            message: '请输入大于0的金额',
            type: 'warning',
            showClose: true,
          });
        }
      },
      skuStockChange (index) {
        if (this.form.skus[index].stock === '') {
          return false
        }
        let reg = /^\d+(\.\d{1,2})?$/;
        if(!reg.test(this.form.skus[index].stock)){
          this.form.skus[index].stock = '';
          this.$message({
            message: '请输入最多保留两位的库存',
            type: 'warning',
            showClose: true,
          });
          return false;
        }

        if (Number(this.form.skus[index].stock) < 0 || Number(this.form.skus[index].stock) === 0) {
          this.form.skus[index].stock = '';
          this.$message({
            message: '请输入大于0的库存',
            type: 'warning',
            showClose: true,
          });
        }
      },
      skuCodeChange (index) {
        let isError = false
        if (this.form.skus[index].skuCode === '') {
          return false
        }

        for (let i in this.form.skus) {
          if (Number(i) !== Number(index)) {
            if (this.form.skus[index].skuCode === this.form.skus[i].skuCode) {
              isError = true
              break;
            }
          }
        }

        if (isError) {
          this.$message({
            message: '商家编码不可重复',
            type: 'warning',
            showClose: true,
          });
          this.form.skus[index].skuCode = ''
          return false
        }

        getGoodsSkuCode(this.form.skus[index].skuCode).then(res => {
          if (res.code === 0) {
            return false
          } else {
            this.form.skus[index].skuCode = ''
            return false
          }
        })
      },
      getFileList (list) {
        let listData = []
        list.forEach((item, index) =>{
          listData.push({
            name: index,
            url: this.imageURL + item,
            response: {
              data: item
            }
          })
        })
       return listData
      },
      formDetailChange (val) {
        this.form.detail = val
      },
      isObject (obj) {
        return Object.prototype.toString.call(obj)==='[object Object]';
      },
      isArray (arr) {
        return Object.prototype.toString.call(arr)==='[object Array]';
      },
      equalsObj (oldData,newData) {
        if(oldData === newData) return true;
        if(this.isObject(oldData) && this.isObject(newData) && Object.keys(oldData).length === Object.keys(newData).length){
          for (const key in oldData) {
            if(!this.equalsObj(oldData[key],newData[key]))
              return false;
          }
        }else if(this.isArray(oldData) && this.isArray(oldData)&&oldData.length === newData.length){
          for (let i = 0,length=oldData.length; i <length; i++) {
            if(!this.equalsObj(oldData[i],newData[i]))
              return false;
          }
        }else{
          return false;
        }

        return true;
      },
      getMerchantClass (level, pid) {
        getMerchantClass(level, pid).then(res => {
          if (res.code === 0) {
            if (level === 1) {
              this.keyword2 = '';
              this.keyword3 = '';
              this.classLevel1 = res.data
            }
            if (level === 2) {
              this.keyword2 = '';
              this.keyword3 = '';
              this.classLevel2 = res.data
              this.classLevel2Show = res.data
            }
            if (level === 3) {
              this.keyword3 = '';
              this.classLevel3 = res.data
              this.classLevel3Show = res.data
            }
          }
        })
      },
      changeLevel (level, id, name) {
        if (level === 1) {
          this.category1Id = id
          this.category1Value = name

          this.category2Id = null
          this.category2Value = ''

          this.category3Id = null
          this.category3Value = ''

          this.getMerchantClass(2, id)
          this.classLevel3 = []
          this.classLevel3Show = []
        }
        if (level === 2) {
          this.category2Id = id
          this.category2Value = name

          this.category3Id = null
          this.category3Value = ''

          this.getMerchantClass(3, id)
        }
        if (level === 3) {
          this.category3Id = id
          this.category3Value = name
        }
      },
      toChangeClass () {
        if (this.category1Id && this.category2Id && this.category3Id) {
          this.form.category1Id = this.category1Id
          this.form.category1Value = this.category1Value
          this.form.category2Id = this.category2Id
          this.form.category2Value = this.category2Value
          this.form.category3Id = this.category3Id
          this.form.category3Value = this.category3Value
          this.form.tag.forEach(item => {
            item.value = ''
          })
          this.getClassAttr()
          this.isPopupClass = false
        } else {
          this.$message({
            message: '请选中商品分类',
            type: 'warning',
            showClose: true,
          });
        }
      },
      category2Change () {
        this.keyword3 = '';
        this.category2Id = '';
        this.classLevel3Show = [];
        let data = this.classLevel2.filter(item => item.name.indexOf(this.keyword2) > -1);
        this.classLevel2Show = data
      },
      category3Change () {
        this.category3Id = '';
        let data = this.classLevel3.filter(item => item.name.indexOf(this.keyword3) > -1);
        this.classLevel3Show = data
      },
    },
    components: {
      tinymceEditor
    }
  }
</script>
